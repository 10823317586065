import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import ShoppingCartItem from "@/views/Index/Components/ShoppingCartItem.vue";
import {mapGetters} from "vuex";
@Component({
  components: {
    ShoppingCartItem
  },
  computed: {
    ...mapGetters(["shoppingCartListTotal","shoppingCartList"])
  }
})
export default class ShoppingCart extends Vue {
  @Provide() loading:boolean = false
  @Provide() checkedAll:boolean = false
  @Provide() checkedList:Array<string> = []
  @Provide() checkedListLength:number = 0
  @Provide() checkedListNum:number = 0
  @Provide() checkedListPrice:number = 0
  @Provide() total:number = -1
  @Provide() listTotal:number = -1

  @Watch('shoppingCartListTotal',{immediate:true, deep:false})
  private shoppingCartListTotalChange():void {
    const _this:any = this;
    _this.total = _this.shoppingCartListTotal
  }

  @Watch('shoppingCartList',{immediate:true, deep:false})
  private shoppingCartListChange():void {
    const _this:any = this;
    if(_this.$base.isNull(_this.shoppingCartList)){
      _this.listTotal = 0
      return
    }
    console.log('%c [ 11 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', _this.shoppingCartList)
    _this.listTotal = _this.shoppingCartList.length
    _this.checkedListLength = _this.checkedListPrice = _this.checkedListNum = 0
    _this.checkedList = []
    _this.shoppingCartList.map((item:any) => {
      if(
        item.commoditySeries != 2 && item.invalidStatus == 0 ||
        item.commoditySeries == 2 && item.disposalStatus == 1 && item.invalidStatus == 0
      ){
        _this.checkedListLength++
        if(item.checked){
          _this.checkedList.push(item.cartId)
          _this.checkedListNum += parseInt(item.cartNum)
          _this.checkedListPrice = _this.$base.accAdd(_this.checkedListPrice,_this.$base.accMul(_this.$base.toFixed(item.cartPrice),item.cartNum))
        }
      }
    })
  }

  @Watch('checkedList',{immediate:false, deep:false})
  private checkedListLengthChange():void {
    const _this:any = this;
    if(_this.checkedList.length >= _this.checkedListLength && _this.checkedListLength > 0){
      _this.checkedAll = true
      return
    }
    _this.checkedAll = false
  }
  created(){
    const _this:any = this;
  }

  /**
   * @Author HS
   * @Date 2021/7/23 9:32 上午
   * @Description: 全选
   * @Params: null
   * @Return: null
  */
  checkedAllFn(){
    const _this:any = this;
    _this.checkedAll = !_this.checkedAll
    _this.checkedList = []
    _this.checkedListPrice = _this.checkedListNum = 0
    _this.shoppingCartList.map((item:any) => {
      if(
        item.commoditySeries != 2 && item.invalidStatus == 0 ||
        item.commoditySeries == 2 && item.disposalStatus == 1 && item.invalidStatus == 0
      ){
        item.checked = _this.checkedAll
      }
    })
    console.log('-------------------------D');
    _this.$store.commit('SET_LIST',JSON.stringify(_this.shoppingCartList))

  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:44 上午
   * @Description: 结算
   * @Params: null
   * @Return: null
  */
  settlementFn(){
    const _this:any = this;
    const str = _this.checkedList.join(',')
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.confirmOrder.path,'i='+str)
    _this.$base.goRouter(path)
  }


  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { number ： id - true } [id]
   * @Params: { string ： iPath - true } [路径]
   * @Return: null
   */
  goDetails(id:number,iPath:string){
    const _this:any = this;
    const link = _this.$base.routeLinkSplice(_this.$IndexConfig[iPath].path,id.toString())
    _this.$base.goRouter(link)
  }

}
